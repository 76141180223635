<template>
  <Layout :tituloPagina="'Perfil de usuario'">
    <div class="row">
      <div class="col-xxl-3">
        <div class="user-sidebar">
          <div class="card">
            <div class="card-body p-0">
              <div class="user-profile-img">
                <img
                  src="@/assets/images/pattern-bg.jpg"
                  class="profile-img profile-foreground-img rounded-top"
                  style="height: 120px"
                />
                <div class="overlay-content rounded-top">
                  <div>
                    <div class="user-nav p-3">
                      <div class="d-flex justify-content-end">
                        <div class="dropdown">
                          <a
                            class="dropdown-toggle"
                            href="javascript: void(0);"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <eva-icon
                              name="more-horizontal-outline"
                              data-eva-width="20"
                              data-eva-height="20"
                              class="fill-white"
                            ></eva-icon>
                          </a>

                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <button class="dropdown-item" @click="seleccionarFoto()">
                                <eva-icon name="repeat" class="text-primary"></eva-icon>
                                {{foto == null ? 'Seleccionar ' : 'Cambiar '}} imagen
                              </button>
                            </li>
                            <li>
                              <button class="dropdown-item" @click="eliminarFoto()">
                                <eva-icon name="trash-2-outline" class="text-danger"></eva-icon>
                                Eliminar imagen
                              </button>
                            </li>
                            <li>
                              <button class="dropdown-item" @click="visibleImagen = true">
                                <eva-icon name="eye-outline" class="text-info"></eva-icon>
                                Ver imagen completa
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-n5 position-relative">
                <div class="text-center">
                  <div v-show="foto == null" class="auth-logo">
                    <img
                      :src="usuarioicon"
                      alt="Foto del usuario"
                      class="avatar-xl rounded-circle img-thumbnail cursor auth-logo-dark"
                      title="Seleccionar imagen"
                      @click="seleccionarFoto()"
                    />
                    <img
                      :src="usuarioiconblanco"
                      alt="Foto del usuario"
                      class="avatar-xl rounded-circle img-thumbnail cursor auth-logo-light"
                      title="Seleccionar imagen"
                      @click="seleccionarFoto()"
                    />
                  </div>
                  <div v-if="foto != null">
                    <img 
                      :src="foto" 
                      class="avatar-xl rounded-circle img-thumbnail cursor" 
                      alt="Foto del usuario" 
                      title="Ver imagen completa"
                      @click="visibleImagen = true"
                    />
                    <vue-easy-lightbox
                      :visible="visibleImagen"
                      :imgs="foto_visor"
                      @hide="visibleImagen = false"
                    ></vue-easy-lightbox>
                  </div>
                  <div class="mt-3">
                    <h5 class="mb-1">
                      {{usuario.nombre}} {{usuario.apellido_paterno}} {{usuario.apellido_materno}}
                    </h5>
                    <div class="mt-4">
                      <h4>Roles</h4>
                      <div v-for="rol in usuario.roles" :key="rol.id">
                        <span class="badge rounded-pill bg-gradient bg-success font-size-15 px-3 m-1">
                          {{rol.nombre}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-3 mt-3"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-9">
        <div class="card">
          <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                data-bs-toggle="tab"
                href="#datos-usuarios"
                role="tab"
                title="Datos del usuario"
              >
                <i class="bx bx-user-circle font-size-20"></i>
                <span class="d-none d-sm-block">Datos del usuario</span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="tab"
                href="#otros-ajustes"
                role="tab"
                title="Otros ajustes"
              >
                <i class="bx bx bx-cog font-size-20"></i>
                <span class="d-none d-sm-block">Otros ajustes</span>
              </a>
            </li>
            <li class="nav-item"></li>
            <li class="nav-item"></li>
          </ul>

          <div class="tab-content p-4">
            <div class="tab-pane active" id="datos-usuarios" role="tabpanel">
              <h3>Claves de acceso</h3>
              <div class="row">
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="identidad" class="form-label">Identidad</label>
                    <input
                      v-model="usuario.identidad"
                      class="form-control"
                      placeholder="Identidad"
                      autocomplete="off"
                      id="identidad"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="clave" class="form-label">Clave</label>
                    <input
                      v-model="usuario.clave"
                      type="password"
                      class="form-control"
                      placeholder="Ingresa clave"
                      id="clave"
                    />
                  </div>
                </div>
              </div>
              <br />
              <h3>Datos personales</h3>
              <div class="row">
                <div class="col-md-6">
                  <label>Nombre</label>
                  <input
                    v-model="usuario.nombre"
                    class="form-control"
                    readonly
                  />
                </div>
                <div class="col-md-3">
                  <label>Apellido paterno</label>
                  <input
                    v-model="usuario.apellido_paterno"
                    class="form-control"
                    readonly
                  />
                </div>
                <div class="col-md-3">
                  <label>Apellido materno</label>
                  <input
                    v-model="usuario.apellido_materno"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-3">
                  <label>Estado</label>
                  <input
                    v-if="usuario.activo == true"
                    value="Activo"
                    class="form-control"
                    readonly
                  />
                  <input
                    v-if="usuario.activo == false"
                    value="Inactivo"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-6">
                  <label>Teléfono</label>
                  <input
                    v-model="usuario.telefono"
                    class="form-control"
                    name="telefono"
                  />
                </div>
                <div class="col-md-6">
                  <label>Correo</label>
                  <input
                    v-model="usuario.correo"
                    class="form-control"
                    name="correo"
                  />
                </div>
              </div>
            </div>
            <div class="tab-pane" id="otros-ajustes" role="tabpanel">
              <h5>Abonos visibles</h5>
              <div class="form-check form-switch form-switch-lg">
                <input
                  class="form-check-input"
                  type="checkbox" id="facturasAbonosVisor"
                  :checked="usuario.ajustes.factura_abonosenvisor"
                  @change="
                    usuario.ajustes.factura_abonosenvisor = !usuario.ajustes.factura_abonosenvisor,
                    actualizarAjustes()
                  "
                />
                <label class="form-check-label" for="facturasAbonosVisor">
                  Abonos visibles en el visor de factura
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="bx-pull-right">
          <div class="btn-group">
            <button class="btn btn-secondary"
              @click="cerrar()">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
            <button
              type="submit"
              class="btn btn-success"
              onclick="return false"
              @click="actualizar()"
              :disabled="bandera_spinner"
            >
              <i 
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save-outline' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              Actualizar
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import VueEasyLightbox from "vue-easy-lightbox"
import API from '@/API.js'

import UsuarioSrv from '@/services/UsuarioSrv.js'
import usuarioicon from '@/assets/img/logoArgus.png'
import usuarioiconblanco from '@/assets/img/logoArgusBlanco.png'

export default {
  name: 'PerfilUsuarioSesion',
  components: {
    Layout,
    VueEasyLightbox,
  },
  data() {
    return {
      tk: localStorage.getItem('argusblack.token'),
      rnd: Math.random(),
      API: API,
      baseUrl: window.location.origin,
      usuarioicon: usuarioicon,
      usuarioiconblanco: usuarioiconblanco,
      idFoto: new Date().getMilliseconds(),
      roles: [],
      usuario: {
        id: null,
        identidad: '',
        clave: null,
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        telefono: null,
        correo: '',
        id_foto: 0,
        activo: true,
        roles: [],
        ajustes:{
          factura_abonosenvisor: false
        }
      },
      usuarios: [],
      foto: null,
      foto_visor: null,
      bandera_spinner: false,
      visibleImagen: false,
    }
  },

  created: function() {
    var self = this
    var idUsuario = this.$route.params.id

    self.usuario.id = idUsuario
    self.cargarUsuario()
  },

  methods: {
    actualizar: function() {
      var self = this
      
      self.bandera_spinner = true
      
      let usuario = Object.assign({}, self.usuario)
      delete usuario.roles

      UsuarioSrv.actualizar(usuario).then(response => {
        iu.msg.success('Se actualizó correctamente')

        // Actualiza la foto del usuario si el usuario actual
        // es el mismo usuario actualizado
        if (self.$store.state.todo.usuario.id == self.usuario.id) {
          iu.bus.emit('fotoUsuarioActualizada')
        }
      }).catch(self.mostrarMensajeError).finally(fin => {
        self.bandera_spinner = false
        
        if (self.usuario.id == self.$store.state.todo.usuario.id && 
          (self.usuario.identidad != self.$store.state.todo.usuario.identidad ||
          self.usuario.clave != self.$store.state.todo.usuario.clave) 
        ) {
          iu.msg.warning('Es necesario volver a iniciar la sesión')
          localStorage.removeItem('argusblack.token')
          self.$router.push({ name: 'login' })
        } else {
          self.cargarUsuario()
        }
      })
    },
    actualizarAjustes() {
      var self = this
      UsuarioSrv.actualizarAjustes({factura_abonosenvisor: self.usuario.ajustes.factura_abonosenvisor}).then(response => {
        iu.msg.success("Se actualizo correctamente el ajuste \"Abonos visibles en el visor de factura\"")
        self.cargarUsuario()
      }).catch(error => {
        let mensaje 
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar tema de layoutScrollable'
        }
        console.log(error)
        iu.msg.error(mensaje)
      })
    },

    cargarUsuario() {
      var self = this
      let prom = UsuarioSrv.actual()
      
      prom.then(response => {
        self.usuario = response.data
        self.rnd = Math.random()

        if(self.usuario.id_foto != null) {
          // self.foto = self.API + '/usuarios/' + self.usuario.id + '/foto?tipo_foto=256x256&_tk=' + self.tk + '&rnd=' + Math.random()
          // self.foto_visor = self.API + '/usuarios/' + self.usuario.id + '/foto?tipo_foto=original&_tk=' + self.tk + '&rnd=' + Math.random()
          self.foto = self.API + '/usuarios/' + self.usuario.id + '/foto?tipo_foto=256x256&_tk=' + self.tk
          self.foto_visor = self.API + '/usuarios/' + self.usuario.id + '/foto?tipo_foto=original&_tk=' + self.tk
        }
      }).catch(self.mostrarMensajeError)

      return prom
    },

    cerrar: function() {
      this.$router.go(-1)
    },

    eliminarFoto: function() {
      var self = this
      self.foto = null
      self.foto_visor = null
      self.usuario.eliminar_foto_antigua = 1
    },

    mostrarMensajeError(error) {
      let message
      try {
        message = error.response.data.message
      } catch(e) {
        message = 'No se pudo cargar el usuario'
      }
      iu.msg.error(message)
      console.log(error)
    },

    seleccionarFoto: function() {
      let self = this
      var inpFoto = document.createElement("input")
      inpFoto.setAttribute('type', 'file')
      var reader = new FileReader()

      inpFoto.click()
      
      reader.onload = function(e) {
        self.foto = this.result
        self.foto_visor = this.result
      }

      inpFoto.addEventListener('change', function(){
        self.usuario.foto = inpFoto.files[0]
        self.usuario.eliminar_foto_antigua = true
        reader.readAsDataURL(self.usuario.foto)
      })
    },
  }
}
</script>

<style scoped>
.avatar-xl {
  height: 10rem;
  width: 10rem;
}
.cursor{
  cursor: pointer;
}
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
</style>